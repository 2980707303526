import { render, staticRenderFns } from "./Branch.vue?vue&type=template&id=67c90c62&scoped=true&"
import script from "./Branch.vue?vue&type=script&lang=ts&"
export * from "./Branch.vue?vue&type=script&lang=ts&"
import style0 from "./Branch.vue?vue&type=style&index=0&id=67c90c62&lang=scss&scoped=true&"
import style1 from "./Branch.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./Branch.vue?vue&type=style&index=2&id=67c90c62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67c90c62",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VContainer,VMain})
