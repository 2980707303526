



































































import BrandAPIConnector from "@/service/BrandAPIConnector";
import { Component, Vue } from "vue-property-decorator";
import loyaltyConfig from "../../../loyalty_config.json"

@Component({
  components: {},
})
export default class CustomerList extends Vue {
  loading = false;
  keyword = "";
  // userList: any = [];
  userList: {
    data: any[];
    total: number;
  } = {
    data: [],
    total: 0
  };
  currentUserNumber: number = 0;
  maxUserNumber: number = 0;
  userAvatarUrl: string = require(`@/assets/img/avatar.png`);
  defaultUserLogo: string = require(`@/assets/img/sable-gray.svg`);

  async mounted() {
    await this.loadingBrandData();
    await this.loadingData();
    // this.currentUserNumber = this.userList.length;
    this.currentUserNumber = this.userList.total;
  }

  async loadingBrandData () {
    this.loading = true;
    if (loyaltyConfig?.brand_id) {
      this.maxUserNumber = 0;
    } else {
      const brandUser = await BrandAPIConnector.getUserBrand();
      if (brandUser) {
        let defaultMaxCustomer = 400;
        switch (brandUser.loyalty_plan) {
          case 'STANDARD': 
            defaultMaxCustomer = 1000;
            break;
          case 'PREMIUM':
            defaultMaxCustomer = 5000;
        }
        const planMaxCustomer = brandUser.max_customer??0;
        this.maxUserNumber = defaultMaxCustomer + planMaxCustomer;
      } else {
        this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลร้านค้าได้");
      }
    }
    this.loading = false;
  }

  async loadingData() {
    this.loading = true;
    const result = await BrandAPIConnector.getCustomerList(this.keyword);
    if (result) {
      console.log(result);
      // this.userList = result;
      this.userList = result  as unknown as { data: any[]; total: number; };
    } else {
      this.$store.dispatch("alert/error", "ไม่สามารถดึงข้อมูลลูกค้าได้");
    }
    this.loading = false;
  }

  showUserProfile(userId: string) {
    this.$router.push({
      name: "Branch_CustomerProfile",
      params: { id: userId },
    });
  }

  replaceDefault(e: any) {
    e.target.src = this.defaultUserLogo;
  }
}
