import { render, staticRenderFns } from "./CustomerList.vue?vue&type=template&id=1d139482&scoped=true&"
import script from "./CustomerList.vue?vue&type=script&lang=ts&"
export * from "./CustomerList.vue?vue&type=script&lang=ts&"
import style0 from "./CustomerList.vue?vue&type=style&index=0&id=1d139482&lang=scss&scoped=true&"
import style1 from "./CustomerList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d139482",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VCard,VProgressLinear,VRow,VSkeletonLoader,VTextField})
