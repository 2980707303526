

























































import BranchAPIConnector from "@/service/BranchAPIConnector";
import { Component, Vue } from "vue-property-decorator";
import { getErrorMessage } from "@/common/crossError";
import moment from "moment";

@Component({
  components: {},
})
export default class BranchCouponDetail extends Vue {
  // Coupon Detail
  couponHistoryId: string = "";
  couponCode: string = "";
  couponImage: string = "";
  couponName: string = "";
  couponStatus: string = "";
  couponIsRestrict: boolean = false;
  couponReceiveDate: string = "";
  couponExpiredDate: string = "";
  couponIsVerify: boolean = false;
  condition = "";

  isLoading: boolean = false;

  mounted() {
    this.couponCode = this.$route.params.couponCode;
    // Getting Coupon detail
    this.initCouponDetail();
  }

  async initCouponDetail() {
    this.isLoading = true;
    const couponData = await BranchAPIConnector.getCouponData(this.couponCode);
    if (couponData) {
      if (couponData.result) {
        this.couponImage = couponData.img;
        this.couponName = couponData.coupon_name;
        this.condition = couponData.condition;
        this.couponHistoryId = couponData._id;
        this.couponIsVerify = couponData.verify;
        if (couponData.verify) {
          this.couponStatus = "receive";
        } else {
          if (couponData.status == "20") {
            this.couponStatus = "expired";
          } else if (couponData.status == "26") {
            this.couponStatus = "used";
          }
        }
        this.couponReceiveDate = moment(new Date(couponData.receive_datetime)).format("DD-MM-YYYY HH:mm");
        if (couponData.is_no_expire_limit && couponData.is_no_expire_use_date) {
          this.couponExpiredDate = "ไม่มีวันหมดอายุ";
        } else {
          this.couponExpiredDate = moment(new Date(couponData.expired_datetime)).format("DD-MM-YYYY HH:mm");
        }
        this.couponIsRestrict = couponData.is_restrict;
        this.isLoading = false;
      } else {
        this.$store.dispatch(
          "alert/error",
          getErrorMessage(couponData.error) ?? "ไม่สามารถดึงข้อมูลรีวอร์ดได้"
        );
        this.$router.push({ name: "Branch_RedeemCoupon" });
      }
    } else {
      this.$store.dispatch("alert/error", "รีวอร์ดโค้ด ไม่ถูกต้อง");
      this.$router.push({ name: "Branch_RedeemCoupon" });
    }
  }

  async useCoupon() {
    this.isLoading = true;
    const useCouponResult = await BranchAPIConnector.useCoupon(
      this.couponHistoryId
    );
    if (useCouponResult) {
      if (useCouponResult.result) {
        this.$store.dispatch("alert/success", "ใช้รีวอร์ดเรียบร้อยแล้ว");
        this.$router.push({ name: "Branch_RedeemCoupon" });
      } else {
        console.log(`Cross Error : ${useCouponResult.error}`);
        this.$store.dispatch(
          "alert/error",
          getErrorMessage(useCouponResult.error) ?? "ไม่สามารถใช้รีวอร์ดได้"
        );
      }
    } else {
      this.$store.dispatch(
        "alert/error",
        "ไม่สามารถใช้รีวอร์ดได้ กรุณาลองใหม่อีกครั้ง"
      );
    }
    this.isLoading = false;
  }
  back () {
    this.$router.push({ name: "Branch_RedeemCoupon"});
  }
}
